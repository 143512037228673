<template>
  <div>
    <div class="content" id="ysCont" @scroll="scrollEvent">
      <div class="contentBox">
        <div class="serchBox">
          <div class="logoImg">
            <img src="../../assets/img/logo-p.png" alt="" srcset="" />
          </div>
          <div class="inpBox">
            <div class="inp">
              <div class="search">
                <div class="iconfont icon-search"></div>
              </div>
              <div class="shu"></div>
              <input
                type="text"
                placeholder="请输入资讯名称搜索"
                v-model="seachTxt"
                name=""
                id=""
                @keyup.enter="searchClick"
              />
              <div class="searchBtn" @click="searchClick">搜索</div>
            </div>
          </div>
        </div>
        <div class="nrContent">
          <div class="cont" v-for="item in list" @click="jump(item.id)">
            <div class="name">{{ item.name }}</div>
            <div class="text">{{ item.content }}</div>
            <div class="time">{{ item.create_time }}</div>
          </div>
        </div>
        <div class="fenye" v-if="totalCount > 20">
          <a-pagination
            :default-current="page.pageNo"
            v-model="page.pageNo"
            :total="totalCount"
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      judge: true,
      shang: "",
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
      seachTxt: "",
      totalCount: 0,
    };
  },
  created() {
    this.listGet();
  },
  methods: {
    onChange(pageNumber) {
      this.member_forumList = [];
      this.page.pageNo = pageNumber;
      this.listGet();
    },
    jump(id) {
      this.$router.push({
        path: "/gonggao",
        query: {
          id: id,
        },
      });
    },
    searchClick() {
      this.page.name = this.seachTxt;
      this.page.pageNo = 1;
      this.totalCount = 0
      this.list = [];
      this.listGet();
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/news_gets", this.page).then((res) => {
        console.log(res);
        this.judge = true;
        this.totalCount = res.totalCount;
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.content = e.content.replace(/<[^>]*>/g, "");
            this.list.push(e);
          }
          this.page.pageNo++;
        } else {
          if (this.page.pageNo != 1) {
            this.$message.warning("已展示全部内容");
          }
        }
      });
    },
    scrollEvent() {
      let top = document.querySelector("#ysCont").scrollTop;
      let height = document.querySelector("#ysCont").scrollHeight;
      let offHeight = document.querySelector("#ysCont").offsetHeight;
      if (offHeight + top - height >= 0) {
        this.listGet();
        // 需要执行的代码
      }
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  // height: calc(100vh - 55px);
  // overflow: auto;
  .contentBox {
    width: 1440px;
    margin: auto;
    box-sizing: border-box;
    .serchBox {
      display: flex;
      padding: 30px 70px;
      align-items: center;
      .logoImg {
        width: 210px;
      }
      .inpBox {
        width: calc(100% - 250px);
        box-sizing: border-box;
        padding-left: 50px;
        .inp {
          border: 2px solid #f66;
          background-color: #fff;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          display: flex;
          overflow: hidden;
          align-items: center;
          position: relative;
          .search {
            width: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .iconfont {
              font-size: 24px;
              color: #999;
            }
          }
          .shu {
            width: 2px;
            height: 20px;
            background-color: #c9c9c9;
          }
          input {
            width: calc(100% - 67px);
            height: 100%;
            padding-left: 20px;
            box-sizing: border-box;
          }
          .searchBtn {
            cursor: pointer;
            width: 90px;
            height: calc(100% - 4px);
            position: absolute;
            right: 2px;
            top: 2px;
            background-color: rgb(233, 7, 7);
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .nrContent {
      overflow: hidden;
      .cont {
        cursor: pointer;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 20px;
        padding: 20px;
        width: calc(50% - 7.5px);
        margin-right: 15px;
        float: left;
        .name {
          font-weight: 600;
          font-size: 18px;
        }
        .text {
          margin-top: 10px;
          font-size: 16px;
          color: #333;
        }
        .time {
          margin-top: 10px;
        }
      }
      .cont:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
